import { HelpOutline, LogoutOutlined, SettingsOutlined } from '@mui/icons-material'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import { useRouter } from 'next/navigation'
import type { SyntheticEvent } from 'react'
import { Fragment, useState } from 'react'

import type { Settings } from '@core/context/settingsContext'

import useIsAdmin from '~/auth/hooks/useIsAdmin'
import { useOverlay } from '~/components/Overlay'
import { useCurrentUser } from '~/data/api/hooks/users'
import { useAuth } from '~/hooks/useAuth'
import routeDirectory from '~/navigation/routes'

import HelpOverlay from './HelpOverlay'

type UserDropdownProps = {
  settings: Settings
}

export default function UserDropdown(props: UserDropdownProps) {
  const isAdmin = useIsAdmin()
  // ** Props
  const { settings } = props

  // ** States
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  // ** Hooks
  const router = useRouter()
  const { logout } = useAuth()
  const { palette } = useTheme()
  const { synopUser } = useCurrentUser()

  // ** Vars
  const { direction } = settings

  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = (url?: string) => {
    if (url) {
      router.push(url)
    }
    setAnchorEl(null)
  }

  const handleLogout = () => {
    logout()
    handleDropdownClose()
  }

  const backgroundColor = palette.background.paper
  const color = palette.secondary.main
  const initials = (synopUser?.name || '')
    .split(' ')
    .map((word) => word[0])
    .join('')

  const helpOverlay = useOverlay()

  return (
    <Fragment>
      <HelpOverlay {...helpOverlay} />
      <Avatar
        onClick={handleDropdownOpen}
        sx={{
          backgroundColor,
          border: 2,
          borderColor: 'secondary.main',
          color,
          ml: 2,
          cursor: 'pointer'
        }}
      >
        {initials}
      </Avatar>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        sx={{ '& .MuiMenu-paper': { width: 230, mt: 4 } }}
        anchorOrigin={{ vertical: 'bottom', horizontal: direction === 'ltr' ? 'right' : 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: direction === 'ltr' ? 'right' : 'left' }}
      >
        <Box sx={{ pt: 2, pb: 3, px: 4 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              sx={{
                backgroundColor,
                border: 2,
                borderColor: 'secondary.main',
                color
              }}
            >
              {initials}
            </Avatar>
            <Box sx={{ display: 'flex', ml: 3, alignItems: 'flex-start', flexDirection: 'column' }}>
              <Typography sx={{ fontWeight: 600 }}>{synopUser?.name}</Typography>
              <Typography variant="body2" sx={{ fontSize: '0.8rem', color: 'text.disabled' }}>
                {synopUser?.roles?.[0]}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ mt: '0 !important' }} />
        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose(routeDirectory.settings.root)}>
          <Box
            sx={{
              py: 2,
              px: 4,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              color: 'text.primary',
              textDecoration: 'none',
              '& svg': {
                mr: 2,
                fontSize: '1.375rem',
                color: 'text.primary'
              }
            }}
          >
            <SettingsOutlined />
            Settings
          </Box>
        </MenuItem>
        {isAdmin && (
          <MenuItem
            onClick={helpOverlay.openOverlay}
            sx={{ py: 2, '& svg': { mr: 2, fontSize: '1.375rem', color: 'text.primary' } }}
          >
            <HelpOutline />
            Get Help
          </MenuItem>
        )}
        <Divider />
        <MenuItem
          onClick={handleLogout}
          sx={{ py: 2, '& svg': { mr: 2, fontSize: '1.375rem', color: 'text.primary' } }}
        >
          <LogoutOutlined />
          Logout
        </MenuItem>
      </Menu>
    </Fragment>
  )
}
