import { AbilityBuilder, createMongoAbility } from '@casl/ability'

import type { UserDataType } from '~/auth/AuthProvider'

export type Actions = 'manage' | 'create' | 'read' | 'update' | 'delete'
export type ACLObj = { action: Actions; subject: string }

export const buildAbilityFor = (user: UserDataType) => {
  const { can, cannot, build } = new AbilityBuilder(createMongoAbility)

  // Give everyone manage-all privileges. `manage` and `all` are special keywords in CASL.
  // `manage` represents any action and `all` represents any subject.
  can('manage', 'all')

  // Modify role-based abilities
  switch (user.role) {
    case 'admin':
      break

    case 'fleet operator':
    case 'driver':
    default:
      cannot('manage', 'admin')

      if (user.sites && user.sites.length > 0) {
        cannot('read', 'charger')
        cannot('read', 'site')
        cannot('read', 'vehicle')

        can('read', 'charger', { depotId: { $in: user.sites } })
        can('read', 'site', { depotId: { $in: user.sites } })
        can('read', 'vehicle', { homeSiteId: { $in: user.sites } })
      }

      cannot('create', 'charger')
      cannot('update', 'charger')

      cannot('create', 'site')
      cannot('update', 'site')

      cannot('create', 'vehicle')
      cannot('update', 'vehicle')

      cannot('create', 'loi')
      cannot('update', 'loi')

      cannot('create', 'alert')
      cannot('update', 'alert')
      break
  }

  return build()
}

export const defaultACLObj: ACLObj = {
  action: 'manage',
  subject: 'all'
}
