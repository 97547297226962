import { Box, Stack } from '@mui/material'
import { useMemo } from 'react'

import { useCurrentOrg } from '~/data/api/hooks/organizations'
import usePageTitle from '~/hooks/usePageTitle'

import getCustomerAssets from './assets'

const defaultLogoUrl = '/images/logos/synop-logo-600-alpha.png'

export default function AuthPageLogo() {
  const customerLogoUrl = useMemo(() => getCustomerAssets()?.logoUrl, [])
  // Causes the page title to be set to the customer's title for white-labeled customers
  usePageTitle()

  const { org } = useCurrentOrg()

  return (
    <Stack alignItems="center" justifyContent="center" sx={{ width: '100%' }}>
      <Box
        alt="Synop Logo"
        component="img"
        height="109px"
        src={customerLogoUrl ?? org?.logoUrl ?? defaultLogoUrl}
        sx={{ my: 4, px: 4, maxWidth: '100vw', objectFit: 'contain' }}
      />
    </Stack>
  )
}
