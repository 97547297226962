// Should include shorter filters later in the list, so that more specific ones have a chance to match first
const customerAssets = [
  {
    filters: ['lightning', 'insights'],
    logoUrl: 'https://synop-prod-assets-v1.s3.amazonaws.com/logos/2ca0ae187e7e1f71017e7ecff1ea0000.png',
    favicon:
      'https://lightningemotors.com/wp-content/themes/lightningmotors-2022/dist/images/favicons/favicon-32x32.png',
    title: 'Lightning Insights'
  },
  {
    filters: ['prologis'],
    logoUrl: 'https://synop-prod-assets.s3.amazonaws.com/logos/2ca0870f7f22014f017f4c6e12c80000.png',
    title: 'Prologis Mobility',
    favicon: 'https://authentication.prologis.com/favicon-32x32.png'
  },
  {
    filters: ['mitra-ev'],
    title: 'Mitra EV',
    logoUrl: 'https://synop-prod-assets-v1.s3.amazonaws.com/logos/2ca00ea88c3fec30018ca6a89844003a.png',
    favicon: 'https://synop-prod-assets-v1.s3.amazonaws.com/icons/2ca00ea88c3fec30018ca6a89844003a-favicon.png'
  },
  {
    filters: ['highland'],
    logoUrl: 'https://synop-prod-assets.s3.amazonaws.com/logos/2ca0ae187e23d1cc017e24030bdb0001.png',
    title: 'Highland Dashboard'
  },
  {
    filters: ['electrada'],
    logoUrl: 'https://synop-prod-assets-v1.s3.amazonaws.com/logos/2ca01ebc8236efd60182370b208d0000.png'
  },
  {
    filters: ['inductai'],
    logoUrl: 'https://synop-prod-assets-v1.s3.amazonaws.com/logos/2ca018a686df96c20186f12b102f0002.png',
    title: 'InductAI',
    favicon: 'https://synop-prod-assets-v1.s3.amazonaws.com/icons/2ca018a686df96c20186f12b102f0002_1719434942456.jpeg'
  },
  {
    filters: ['inspiration'],
    logoUrl: 'https://synop-prod-assets-v1.s3.amazonaws.com/logos/2ca0089d8ec55325018f2b2d3337009a_logo.png',
    title: 'Inspiration',
    favicon: 'https://synop-prod-assets-v1.s3.amazonaws.com/icons/2ca0089d8ec55325018f2b2d3337009a_icon.png'
  },
  {
    filters: ['ztrip'],
    logoUrl: 'https://synop-prod-assets-v1.s3.amazonaws.com/logos/2ca0089d8ec55325018f2b2d3337009a_logo.png',
    title: 'zTrip',
    favicon: 'https://synop-prod-assets-v1.s3.amazonaws.com/icons/2ca0089d8ec55325018f2b2d3337009a_icon.png'
  },
  {
    filters: ['revolv'],
    logoUrl: 'https://synop-prod-assets.s3.amazonaws.com/logos/4028d60b7e551c60017e552a43d50000.png'
  },
  {
    filters: ['chubu'],
    logoUrl: 'https://synop-prod-assets-v1.s3.amazonaws.com/logos/chubu-logo.png'
  }
]

export default function getCustomerAssets() {
  const hostURL = location.host.toLocaleLowerCase()
  return customerAssets.find(({ filters }) => filters.some((filter) => hostURL.includes(filter)))
}
