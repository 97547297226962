import { Stack, Typography } from '@mui/material'

import ColorDot from '~/components/ColorDot'
import type { NotificationModelV1 } from '~/data/api/client'
import useTimeFormatter from '~/hooks/useTimeFormatter'

type NotificationStatusTileProps = {
  notification: NotificationModelV1
  emphasized?: boolean
}

export default function StatusTile({ notification, emphasized = false }: NotificationStatusTileProps) {
  const formatDateTime = useTimeFormatter()

  const { inactiveAt } = notification

  const isInactive = Boolean(inactiveAt)

  if (isInactive) {
    return (
      <Stack>
        <Typography variant={emphasized ? 'body1' : 'body2'} color="text.primary">
          Resolved
        </Typography>
        <Typography variant="caption">{formatDateTime(inactiveAt).timeOnDate}</Typography>
      </Stack>
    )
  } else {
    return (
      <Stack alignItems="center" direction="row" spacing={2}>
        <ColorDot color="primary.main" />
        <Typography
          sx={{ fontWeight: emphasized ? 600 : 400 }}
          variant={emphasized ? 'subtitle1' : 'body2'}
          color="text.primary"
        >
          Active
        </Typography>
      </Stack>
    )
  }
}
