import { type ReactNode } from 'react'

import { useSettings } from '@core/hooks/useSettings'
import Layout from '@core/layouts/Layout'

import { LoadingMessage } from '~/components/Loaders'
import useNavItems from '~/navigation/vertical'
import { useMediaQuery } from '~/theme/hooks'

import AppBarContent from './components/AppBarContent'
import HeaderBranding from './components/HeaderBranding'
import InterstitialPage, { useInterstitialStatus } from './components/InterstitialPage'

interface Props {
  children: ReactNode
  contentHeightFixed?: boolean
}

const UserLayout = ({ children, contentHeightFixed }: Props) => {
  // ** Hooks
  const { settings, saveSettings } = useSettings()
  const { status: interstitialStatus, isLoading } = useInterstitialStatus()

  /**
   *  The below variable will hide the current layout menu at given screen size.
   *  The menu will be accessible from the Hamburger icon only (Vertical Overlay Menu).
   *  You can change the screen size from which you want to hide the current layout menu.
   *  Please refer useMediaQuery() hook: https://mui.com/material-ui/react-use-media-query/,
   *  to know more about what values can be passed to this hook.
   *  ! Do not change this value unless you know what you are doing. It can break the template.
   */
  const hidden = useMediaQuery((theme) => theme.breakpoints.down('lg'))

  if (hidden && settings.layout === 'horizontal') {
    settings.layout = 'vertical'
  }

  const navItems = useNavItems()

  if (isLoading) return <LoadingMessage />
  return interstitialStatus ? (
    <InterstitialPage status={interstitialStatus} />
  ) : (
    <Layout
      hidden={hidden}
      settings={settings}
      saveSettings={saveSettings}
      contentHeightFixed={contentHeightFixed}
      scrollToTop={() => null}
      verticalLayoutProps={{
        navMenu: {
          branding: HeaderBranding,
          navItems
        },
        appBar: {
          content: (props) => (
            <AppBarContent
              hidden={hidden}
              settings={settings}
              saveSettings={saveSettings}
              toggleNavVisibility={props.toggleNavVisibility}
            />
          )
        }
      }}
    >
      {children}
    </Layout>
  )
}

export default UserLayout
