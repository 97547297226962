import { useMemo } from 'react'

import formatDateTime from '~/data/formatters/datetime'

import { useUserPrefs } from './useUserPrefs'

export default function useTimeFormatter() {
  const { preferredDateFormat, preferredTimeFormat, preferredTimeZone } = useUserPrefs()

  return useMemo(
    () =>
      formatDateTime({
        preferredDateFormat,
        preferredTimeFormat,
        preferredTimeZone
      }),
    [preferredDateFormat, preferredTimeFormat, preferredTimeZone]
  )
}
