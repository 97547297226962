import { ErrorOutline } from '@mui/icons-material'
import { Chip, alpha, useTheme } from '@mui/material'
import { useMemo } from 'react'

import type { Priority } from '~/data/types/notification'

type PriorityPillProps = {
  priority?: Priority
}

export default function PriorityPill({ priority }: PriorityPillProps) {
  const { error, warning, secondary } = useTheme().palette

  const color = useMemo(() => {
    switch (priority) {
      case 'Critical':
        return error.dark
      case 'High':
        return error.main
      case 'Medium':
        return warning.main
      case 'Low':
        return secondary.main
      default:
        return alpha(secondary.main, 0.04)
    }
  }, [priority, error, warning, secondary])

  return (
    <Chip
      icon={
        priority === 'Critical' ? (
          <ErrorOutline
            sx={{
              hight: 18,
              width: 18,
              color: `${error.contrastText} !important`
            }}
          />
        ) : undefined
      }
      label={priority ?? 'Unknown Status'}
      size="small"
      sx={{
        color: error.contrastText,
        borderColor: error.contrastText,
        bgcolor: color
      }}
    />
  )
}
