import { useLayoutEffect, useRef } from 'react'

export default function useFirstRender(callback: () => void) {
  const isFirstRender = useRef(true)

  // We use useLayoutEffect here so that the callback is fired synchronously
  useLayoutEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      callback()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
