import type { PaletteColor, Theme, UseMediaQueryOptions } from '@mui/material'
import { alpha, useMediaQuery as useMediaQueryMui, useTheme } from '@mui/material'
import { get, isObject } from 'lodash'
import { useMemo } from 'react'

/**
 * Basic wrapper around MUI's `useMediaQuery` hook that provides the theme type
 */
export function useMediaQuery(queryInput: string | ((theme: Theme) => string), options?: UseMediaQueryOptions) {
  return useMediaQueryMui(queryInput, options)
}

/**
 * Checks if the color argument is a theme value and returns the corresponding color from the
 * palette. Otherwise, returns the color argument unchanged.
 */
export function usePaletteColor(path: string, opacity?: number): string {
  const { palette } = useTheme()
  return useMemo(() => {
    const colorObj = get(palette, path)
    const color = isObject(colorObj) ? (colorObj as PaletteColor).main : typeof colorObj === 'string' ? colorObj : path

    if (typeof opacity === 'undefined') return color
    try {
      // This can fail if the input color is not a valid color string
      return alpha(color, opacity)
    } catch {
      return color
    }
  }, [palette, path, opacity])
}
