import { signOut } from 'aws-amplify/auth'

import AuthPage from './AuthPage'
import MfaQrCode from './MfaQrCode'

export default function MfaSetup() {
  return (
    <AuthPage
      subtitle="Your organization now requires Multi-factor Authentication."
      title="Multi-factor Authentication"
    >
      <MfaQrCode isRequired onCancel={signOut} onError={signOut} />
    </AuthPage>
  )
}
