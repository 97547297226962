import { Menu } from '@mui/icons-material'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

import type { Settings } from '@core/context/settingsContext'

import NotificationsDropdown from '~/app/notifications/components/Dropdown'

// import LanguageDropdown from '@core/layouts/components/shared-components/LanguageDropdown'
// import ModeToggler from '@core/layouts/components/shared-components/ModeToggler'
// import type { ShortcutsType } from '@core/layouts/components/shared-components/ShortcutsDropdown'
// import ShortcutsDropdown from '@core/layouts/components/shared-components/ShortcutsDropdown'
import UserDropdown from './UserDropdown'

// import Autocomplete from '~/layouts/components/Autocomplete'

interface Props {
  hidden: boolean
  settings: Settings
  toggleNavVisibility: () => void
  saveSettings: (values: Settings) => void
}

const AppBarContent = (props: Props) => {
  // ** Props
  const { hidden, settings, toggleNavVisibility } = props

  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box className="actions-left" sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
        {hidden && !settings.navHidden ? (
          <IconButton color="inherit" data-cy="nav-menu" sx={{ ml: -2.75 }} onClick={toggleNavVisibility}>
            <Menu />
          </IconButton>
        ) : null}
        {/* <Autocomplete hidden={hidden} settings={settings} /> */}
      </Box>
      <Box className="actions-right" sx={{ display: 'flex', alignItems: 'center' }}>
        {/* <LanguageDropdown settings={settings} saveSettings={saveSettings} /> */}
        {/* <ModeToggler settings={settings} saveSettings={saveSettings} /> */}
        {/* <ShortcutsDropdown settings={settings} shortcuts={shortcuts} /> */}
        <NotificationsDropdown settings={settings} />
        <UserDropdown settings={settings} />
      </Box>
    </Box>
  )
}

export default AppBarContent
