import type { LoadingButtonProps } from '@mui/lab/LoadingButton'
import MuiLoadingButton from '@mui/lab/LoadingButton'

/**
 * Trivial wrapper around MUI's `LoadingButton` to wrap the children in a `span`. This is to
 * avoid crashing the application on Google page translation. See here for more: https://github.com/mui/material-ui/pull/35198
 *
 * This issue will be fixed in MUI v6, at which point this component can be removed.
 */
export default function LoadingButton({ children, ...rest }: LoadingButtonProps) {
  return (
    <MuiLoadingButton {...rest}>
      <span>{children}</span>
    </MuiLoadingButton>
  )
}
