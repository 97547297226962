import { Box, Stack } from '@mui/material'

import { useCloakingInfo, useRevertToPreviousOrganization } from '~/store/slices/currentOrg'

import { BannerBar, BannerButton, BannerTypography } from './components'

export default function CloakingBanner() {
  const { isCloaking, name, parentName } = useCloakingInfo()
  const popOrganization = useRevertToPreviousOrganization()

  if (!isCloaking) return null

  return (
    <BannerBar backgroundColor="primary.main">
      <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center" spacing={6}>
        <Box>
          <BannerTypography color="white">Signed in as:</BannerTypography>{' '}
          <BannerTypography color="white" bold>
            {name ?? ''}
          </BannerTypography>
        </Box>

        <BannerButton darkBackground variant="outlined" size="small" onClick={() => popOrganization()}>
          Return to {parentName ?? ''}
        </BannerButton>
      </Stack>
    </BannerBar>
  )
}
