/**
 * Pluralize a string based on a count. The plural form defaults the the singular form + 's'.
 */
export function pluralize(count: number, singular: string, plural?: string) {
  return count === 1 ? singular : plural ?? `${singular}s`
}

/**
 * Pluralize a string based on a count, including the count in the result.
 */
export function pluralizeWithCount(count: number, singular: string, plural?: string) {
  return `${count} ${pluralize(count, singular, plural)}`
}

/**
 * Applies a map of replacements to a string. Replacements occur in the order they are defined in the map.
 * It is possible for earlier replacements to be overwritten by later replacements:
 *
 * ```ts
 * const replaced = replaceMap('aba', { ab: 'ba', ba: 'ab' })
 * expect(replaced).toBe('baa') // fails, `replaced` is 'aba'
 * ```
 *
 * @param str The input string to replace values of
 * @param map The map of replacements to apply
 * @example
 * replaceMap('Hello, world!', { 'Hello': 'Goodbye', 'world': 'cruel world' })
 *   => 'Goodbye, cruel world!'
 */
export function replaceMap(str: string, map: Record<string, string>) {
  return Object.entries(map).reduce((acc, [key, value]) => acc.replaceAll(key, value), str)
}

/**
 * Truncate a string from the middle instead of the end. e.g. "A really long string" -> "A real...string" instead of "A really l..."
 * @param str The string to be truncated
 * @param maxCharacters The maximum number of characters to allow before truncating. The final string will have this many characters including the ellipses.
 * @returns
 */
export const middleTruncate = (str: string, maxCharacters: number) => {
  if (str.length <= maxCharacters) {
    return str
  }
  const partLength = Math.floor((maxCharacters - 3) / 2) // Subtract 3 for the ellipsis and divide by 2
  return str.slice(0, partLength) + '...' + str.slice(-partLength)
}
