import { usePathname, useRouter } from 'next/navigation'
import { ReactElement, ReactNode, useEffect } from 'react'

import { useAuth } from '~/hooks/useAuth'

interface AuthGuardProps {
  children: ReactNode
  fallback: ReactElement | null
}

const AuthGuard = (props: AuthGuardProps) => {
  const { children, fallback } = props
  const auth = useAuth()
  const router = useRouter()
  const pathname = usePathname()

  useEffect(
    () => {
      if (auth.user === null && !window.localStorage.getItem('userData')) {
        if (pathname !== '/') {
          router.replace('/login', {
            // From the Materialize template, does not work with the app router
            /* query: { returnUrl: pathname } */
          })
        } else {
          router.replace('/login')
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname]
  )

  if (auth.loading || auth.user === null) {
    return fallback
  }

  return <>{children}</>
}

export default AuthGuard
