import { Grid, Typography } from '@mui/material'
import { type ReactElement, cloneElement } from 'react'

type AuthPageHeaderProps = {
  subtitle?: string
  title: string
  Icon?: ReactElement
}

export default function AuthPageHeader({ subtitle, title, Icon }: AuthPageHeaderProps) {
  return (
    <>
      <Grid container item spacing={1} sx={{ alignItems: 'center' }}>
        {Icon && <Grid item>{cloneElement(Icon, { sx: { display: 'block' } })}</Grid>}
        <Grid item>
          <Typography variant="h4">{title}</Typography>
        </Grid>
      </Grid>
      {subtitle && (
        <Grid item>
          <Typography variant="subtitle1">{subtitle}</Typography>
        </Grid>
      )}
    </>
  )
}
