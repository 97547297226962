import type { Theme } from '@aws-amplify/ui-react'
import { translations, useTheme as useAmplifyTheme } from '@aws-amplify/ui-react'
import type { DefaultComponents } from '@aws-amplify/ui-react/dist/types/components/Authenticator/hooks/useCustomComponents/defaultComponents'
import { LockReset, Person } from '@mui/icons-material'
import { Grid, useTheme } from '@mui/material'
import { I18n } from 'aws-amplify/utils'

import AuthPageHeader from './AuthPageHeader'
import AuthPageLogo from './AuthPageLogo'

// This updates the TOTP (Time-based One-Time Password) language to be something users can understand
I18n.putVocabularies(translations)
I18n.putVocabularies({
  en: {
    'Confirm TOTP Code': 'Authentication Code'
  }
})

const AuthCustomizations: DefaultComponents = {
  Header() {
    return <AuthPageLogo />
  },
  SignIn: {
    Header() {
      return (
        <Grid container sx={{ px: 8, pt: 8 }}>
          <AuthPageHeader
            Icon={<Person />}
            subtitle="Provide your email address and password to continue."
            title="Sign In"
          />
        </Grid>
      )
    }
  },
  ForgotPassword: {
    Header() {
      return (
        <Grid container spacing={2}>
          <AuthPageHeader
            Icon={<LockReset />}
            subtitle="Provide your email address to reset your password."
            title="Forgot Password"
          />
        </Grid>
      )
    }
  }
}

export default AuthCustomizations

export function useAuthTheme(): Theme {
  const { background, primary } = useTheme().palette
  const { tokens } = useAmplifyTheme()
  return {
    name: 'Synop theme',
    tokens: {
      components: {
        button: {
          // Styles for the "button link" (e.g. "Forgot your password?" or "Back to Sign In")
          link: { color: primary.light },

          // Styles for the primary button (e.g. "Sign in" or "Send code")
          primary: {
            backgroundColor: primary.main,
            _active: { backgroundColor: primary.light },
            _focus: { backgroundColor: primary.dark },
            _hover: { backgroundColor: primary.dark }
          }
        },
        authenticator: {
          // Sets the page background color
          modal: { backgroundColor: background.default },
          router: {
            boxShadow: tokens.shadows.large,
            borderStyle: 'none'
          },
          footer: { paddingBottom: '0' }
        }
      }
    }
  }
}
