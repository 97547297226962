import { Card, CardContent, Grid, Stack } from '@mui/material'
import type { PropsWithChildren } from 'react'
import React from 'react'

import AuthPageHeader from '../AuthPageHeader'
import AuthPageLogo from '../AuthPageLogo'

type AuthPageProps = PropsWithChildren<{
  actions?: React.ReactNode
  subtitle?: string
  title: string
}>

const AuthPage = ({ actions, children, subtitle, title }: AuthPageProps) => {
  return (
    <Stack>
      <AuthPageLogo />
      <Card sx={{ maxWidth: '760px', width: '90vw' }} elevation={8}>
        <CardContent>
          <Grid container spacing={4}>
            <AuthPageHeader subtitle={subtitle} title={title} />
            <Grid item>{children}</Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Stack direction="row" spacing={4}>
                {actions}
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Stack>
  )
}

export default AuthPage
