import type { TextFieldProps } from '@mui/material'
import { InputAdornment, TextField } from '@mui/material'
import { useController, useFormContext } from 'react-hook-form'

import InfoTooltip from '../InfoTooltip'

type TextFormFieldProps = {
  name: string
  label: string
  defaultHelperText?: string
  tooltip?: string
} & Omit<TextFieldProps, 'name' | 'label' | 'error' | 'value' | 'defaultValue' | 'helperText'>

export default function TextFormField({
  name,
  onChange,
  defaultHelperText,
  fullWidth = true,
  tooltip,
  ...rest
}: TextFormFieldProps) {
  const { control } = useFormContext()
  const { field, fieldState } = useController({ name, control })

  const multilineStyles = { ...(rest.multiline && { alignSelf: 'flex-start' }) }

  return (
    <TextField
      {...field}
      {...rest}
      fullWidth={fullWidth}
      error={!!fieldState.error}
      helperText={fieldState.error?.message || defaultHelperText}
      onChange={(e) => {
        field.onChange(e)
        onChange?.(e)
      }}
      InputProps={{
        ...rest.InputProps,
        endAdornment: (
          <InputAdornment position="end" sx={multilineStyles}>
            <InfoTooltip sx={multilineStyles} title={tooltip} />
            {rest.InputProps?.endAdornment}
          </InputAdornment>
        )
      }}
      value={field.value ?? ''}
      variant="outlined"
    />
  )
}

type TextFormFieldWithTrimProps = {
  replaceInner?: boolean
} & TextFormFieldProps

export function TextFormFieldWithTrim({ name, inputProps, replaceInner, ...rest }: TextFormFieldWithTrimProps) {
  const { setValue } = useFormContext()
  return (
    <TextFormField
      name={name}
      inputProps={{
        onBlur: ({ target }) => {
          let newValue = target.value.trim()
          if (replaceInner) {
            newValue = newValue.replace(/ +/g, '_')
          }
          setValue(name, newValue, { shouldValidate: true })
        },
        ...(inputProps ?? {})
      }}
      {...rest}
    />
  )
}
