import { type TypographyOptions } from '@mui/material/styles/createTypography'

import '@fontsource/open-sans/400.css'
import '@fontsource/open-sans/600.css'
import '@fontsource/open-sans/700.css'
import '@fontsource/titillium-web/400.css'

interface ExtendedTypographyOptions extends TypographyOptions {
  h7: React.CSSProperties
}

export const bodyFontFamily = 'Open Sans'
export const headerFontFamily = 'Titillium Web'

const typography = {
  fontFamily: [headerFontFamily, bodyFontFamily].join(','),

  h1: {
    fontFamily: headerFontFamily,
    fontWeight: 600,
    fontSize: '96px',
    lineHeight: 112 / 96,
    letterSpacing: '-1.5px'
  },

  h2: {
    fontFamily: headerFontFamily,
    fontWeight: 300,
    fontSize: '60px',
    lineHeight: 72 / 60,
    letterSpacing: '-0.5px'
  },

  h3: {
    fontFamily: headerFontFamily,
    fontWeight: 600,
    fontSize: '48px',
    lineHeight: 56 / 48
  },

  h4: {
    fontFamily: headerFontFamily,
    fontWeight: 400,
    fontSize: '34px',
    lineHeight: 42 / 34,
    letterSpacing: '0.25px'
  },

  h5: {
    fontFamily: bodyFontFamily,
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: 32 / 24
  },

  h6: {
    fontFamily: bodyFontFamily,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: 32 / 20,
    letterSpacing: '0.15px'
  },

  h7: {
    fontFamily: bodyFontFamily,
    fontStyle: 'semibold',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: 24 / 16,
    letterSpacing: '0.15px'
  },

  subtitle1: {
    fontFamily: bodyFontFamily,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: 28 / 16,
    letterSpacing: '0.15px'
  },

  subtitle2: {
    fontFamily: bodyFontFamily,
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: 22 / 14,
    letterSpacing: '0.1px'
  },

  body1: {
    fontFamily: bodyFontFamily,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: 1.5,
    letterSpacing: '0.15px'
  },

  body2: {
    fontFamily: bodyFontFamily,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: 20 / 14,
    letterSpacing: '0.17px'
  },

  caption: {
    fontFamily: bodyFontFamily,
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: 20 / 12,
    letterSpacing: '0.4px'
  },

  overline: {
    fontFamily: bodyFontFamily,
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: 32 / 12,
    letterSpacing: '1px',
    textTransform: 'uppercase'
  }
} satisfies ExtendedTypographyOptions

export default typography
