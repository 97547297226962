import { alpha } from '@mui/material'

import { OwnerStateThemeType } from './'

const Rating = () => {
  return {
    MuiRating: {
      styleOverrides: {
        root: ({ theme }: OwnerStateThemeType) => ({
          color: theme.palette.warning.main,
          '& svg': {
            flexShrink: 0
          }
        }),
        iconEmpty: ({ theme }: OwnerStateThemeType) => ({
          color: alpha(theme.palette.customColors.main, 0.22)
        })
      }
    }
  }
}

export default Rating
