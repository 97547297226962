import { Typography } from '@mui/material'

import Link from '~/components/Link'
import { Overlay, createOverlay } from '~/components/Overlay'

const HelpOverlay = createOverlay(() => {
  return (
    <Overlay>
      <Overlay.Header title="Need Help?" />
      <Overlay.Content>
        <Typography variant="body1" sx={{ pt: 4, pb: 8 }}>
          Visit our{' '}
          <Link href="https://synop.readme.io/" sx={{ fontWeight: 'bold' }} target="_blank">
            documentation hub
          </Link>{' '}
          or email us at{' '}
          <Link href="mailto:support@synop.ai" target="_blank">
            support@synop.ai
          </Link>
          .
        </Typography>
      </Overlay.Content>
    </Overlay>
  )
})

export default HelpOverlay
