import { type Components, type Theme, alpha } from '@mui/material'

import lightPalette from './palettes/light'
import typography, { bodyFontFamily } from './typography'

const componentOverrides: Components<Theme> = {
  MuiAlert: {
    styleOverrides: {
      root: {
        '& .MuiAlertTitle-root': {
          ...typography.body2,
          fontWeight: 700,
          marginBottom: 0
        },
        '& .MuiAlert-icon': {
          alignSelf: 'center'
        }
      },
      standardInfo: ({ theme }) => ({
        color: theme.palette.primary.dark,
        backgroundColor: alpha(theme.palette.primary.main, 0.08),
        '& .MuiAlertTitle-root': { color: theme.palette.primary.dark },
        '& .MuiAlert-icon': { color: theme.palette.primary.main }
      }),
      standardWarning: ({ theme }) => ({
        color: theme.palette.warning.dark,
        '& .MuiAlertTitle-root': { color: theme.palette.warning.dark },
        '& .MuiAlert-icon': { color: theme.palette.warning.main }
      }),
      standardError: ({ theme }) => ({
        color: theme.palette.error.dark,
        backgroundColor: alpha(theme.palette.error.main, 0.08),
        '& .MuiAlertTitle-root': { color: theme.palette.error.dark },
        '& .MuiAlert-icon': { color: theme.palette.error.main }
      })
    }
  },

  MuiAutocomplete: {
    defaultProps: {
      size: 'small'
    }
  },

  MuiAvatar: {
    styleOverrides: {
      root: {
        fontFamily: bodyFontFamily,
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: 20 / 14
      }
    }
  },

  MuiButton: {
    styleOverrides: {
      contained: ({ theme }) => ({
        boxShadow: 'none',
        padding: `${theme.spacing(2, 4)}`
      }),
      outlined: ({ theme }) => ({
        padding: `${theme.spacing(1.75, 4)}`
      })
    }
  },

  MuiCard: {
    defaultProps: {
      elevation: 3
    }
  },

  MuiDialog: {
    styleOverrides: {
      paper: { borderRadius: '1rem' },
      paperFullScreen: { borderRadius: 0 }
    }
  },

  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: '8px 24px'
      }
    }
  },

  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: '16px 24px 24px'
      }
    }
  },

  MuiDivider: {
    styleOverrides: {
      root: {
        color: 'rgba(0, 0, 0, 0.12)'
      }
    }
  },

  MuiFormControl: {
    defaultProps: {
      size: 'small'
    }
  },

  MuiInputBase: {
    styleOverrides: {
      input: {
        '&::placeholder': {
          color: 'black'
        },
        '&[type=number]': {
          MozAppearance: 'textfield'
        },
        '&::-webkit-outer-spin-button': {
          WebkitAppearance: 'none',
          margin: 0
        },
        '&::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: 0
        }
      },
      inputAdornedEnd: {
        color: 'primary'
      }
    }
  },

  MuiLinearProgress: {
    styleOverrides: {
      bar: {
        borderRadius: '8px'
      },
      root: {
        borderRadius: '8px'
      }
    }
  },

  MuiSelect: {
    defaultProps: {
      size: 'small'
    }
  },

  MuiTablePagination: {
    styleOverrides: {
      actions: {
        color: lightPalette.primary.main
      }
    }
  },

  MuiTextField: {
    defaultProps: {
      size: 'small'
    }
  },

  MuiToggleButton: {
    styleOverrides: {
      root: {
        color: lightPalette.text.secondary,
        '&.Mui-selected': {
          color: 'white',
          backgroundColor: lightPalette.primary.main,
          '&:hover': {
            color: lightPalette.text.secondary
          }
        }
      }
    }
  },

  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontFamily: bodyFontFamily,
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: 20 / 14
      }
    }
  }
}

export default componentOverrides
