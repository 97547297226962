import { combineReducers } from '@reduxjs/toolkit'

import chargerOverviewReducer from '~/app/chargers/slice'
import siteOverviewReducer from '~/app/sites/slice'
import vehicleOverviewReducer from '~/app/vehicles/slice'
import { enhancedApi } from '~/data/api/client'

import currentOrganizationReducer from './slices/currentOrg'
import displayReducer from './slices/display'
import notificationOverlayReducer from './slices/notifications'

const rootReducer = combineReducers({
  currentOrganization: currentOrganizationReducer,
  display: displayReducer,
  notificationOverlay: notificationOverlayReducer,
  chargerOverview: chargerOverviewReducer,
  siteOverview: siteOverviewReducer,
  vehicleOverview: vehicleOverviewReducer,
  [enhancedApi.reducerPath]: enhancedApi.reducer
})

export default rootReducer
