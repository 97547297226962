import type { ChartingPalette } from '../typeExtensions'

const chartingPalette: ChartingPalette = {
  chart1: '#5EACCC',
  chart2: '#6689E7',
  chart3: '#00787F',
  chart4: '#4A529E',
  chart5: '#FF8241',
  chart6: '#FF656A',
  chart7: '#CA5AA8',
  chart8: '#6D3F9E'
}

export default chartingPalette
