import { Close } from '@mui/icons-material'
import { Box, Grid, IconButton, Stack } from '@mui/material'
import dayjs from 'dayjs'
import { usePathname } from 'next/navigation'
import { useCallback } from 'react'

import Center from '~/components/Center'
import { useUpdateUserMutation } from '~/data/api/client'
import { useCurrentUser } from '~/data/api/hooks/users'
import { replaceMap } from '~/data/formatters/string'
import { useLocalStorageState } from '~/hooks/useLocalStorageState'
import routeDirectory from '~/navigation/routes'
import { useSnackbarControls } from '~/store/slices/display'
import { useMediaQuery, usePaletteColor } from '~/theme/hooks'

import { BannerBar, BannerButton, BannerTypography } from './components'

export default function TimezoneBanner() {
  const { synopUser } = useCurrentUser()
  const { actualTz, preferredTz, setStoredTz, removeStoredTz, onSettingsPage, shouldPrompt } = useTimezoneBanner()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.only('xs'))
  const { openSnackbar } = useSnackbarControls()
  const warningColor = usePaletteColor('warning', 0.3)

  const [updateUser, updateUserResponse] = useUpdateUserMutation()
  const updateTimezone = async () => {
    removeStoredTz()
    const result = await updateUser({ userModel: { ...synopUser, preferredTimeZone: actualTz } })

    if (result.error) {
      openSnackbar('Failed to update timezone', { severity: 'error' })
    } else {
      openSnackbar('Timezone updated')
    }
  }

  if (!shouldPrompt) return null

  const message = <TimezoneMessage current={actualTz} saved={preferredTz} />

  const updateButton = (
    <BannerButton variant="outlined" size="small" onClick={updateTimezone} loading={updateUserResponse.isLoading}>
      Update Timezone
    </BannerButton>
  )

  // Closing the banner is not allowed on the user settings page
  const closeButton = !onSettingsPage && (
    <IconButton edge="start" onClick={() => setStoredTz({ actual: actualTz, preferred: preferredTz })} size="small">
      <Close />
    </IconButton>
  )

  if (isMobile) {
    // On small screens position the update button below the message and close button
    return (
      <BannerBar backgroundColor={warningColor}>
        <Grid container rowSpacing={3}>
          <Grid item xs={12}>
            <Stack direction="row" width="100%" justifyContent="space-between" alignItems="flex-start" spacing={2}>
              {message}
              {closeButton}
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Center.Horizontal>{updateButton}</Center.Horizontal>
          </Grid>
        </Grid>
      </BannerBar>
    )
  } else {
    // On larger screens position the message and buttons in a row
    return (
      <BannerBar backgroundColor={warningColor}>
        <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center" spacing={2}>
          {message}
          <Stack direction="row" alignItems="center" spacing={2} flexShrink={0}>
            {updateButton}
            {closeButton}
          </Stack>
        </Stack>
      </BannerBar>
    )
  }
}

function TimezoneMessage({ current, saved }: { current: string; saved?: string }) {
  if (!saved) {
    return (
      <Box>
        <BannerTypography>You currently do not have a timezone set. Would you like to update to</BannerTypography>{' '}
        <TimezoneTypography timezone={current} />
        <BannerTypography>?</BannerTypography>
      </Box>
    )
  }

  return (
    <Stack>
      <Box>
        <BannerTypography>It looks like you're outside of your saved timezone:</BannerTypography>{' '}
        <TimezoneTypography timezone={saved} />
      </Box>

      <Box>
        <BannerTypography>Would you like to update to:</BannerTypography> <TimezoneTypography timezone={current} />
        <BannerTypography>?</BannerTypography>
      </Box>
    </Stack>
  )
}

function TimezoneTypography({ timezone }: { timezone: string }) {
  return (
    <BannerTypography bold noWrap>
      {formatTimezone(timezone)}
    </BannerTypography>
  )
}

const storageKey = 'tzBannerSnoozed'
type TzBannerValue = { actual: string | undefined; preferred: string | undefined }

/**
 * Product Requirements:
 *   - The user is informed when their "Preferred Timezone" does not match the "Actual Timezone" on
 *     their computer.
 *   - Users can directly update their "Preferred Timezone" to match their "Actual Timezone" directly
 *     from the banner.
 *   - Users can snooze or suppress this message from the banner, until the next time the user's
 *     "Actual Timezone" changes, or the user's cache clears (either manually, or by logging out of
 *     the app).
 *   - Even if it is snoozed or suppressed, the banner should appear on the user's General Settings page.
 */
function useTimezoneBanner() {
  const actualTz = dayjs.tz.guess()

  const { synopUser } = useCurrentUser()
  const { preferredTimeZone: preferredTz } = synopUser ?? {}

  // The setting is global across the application and user-specific
  const [storedTz, setStoredTz, removeStoredTz] = useLocalStorageState<TzBannerValue>(storageKey, {
    ignorePath: true,
    userSpecific: true,
    invariantFn: useCallback(
      ({ value }: { value: TzBannerValue }) => {
        // If the preferred timezone has changed since the last snooze, clear the snooze
        if (preferredTz && value.preferred !== preferredTz) return 'preferred timezone changed'
        return null
      },
      [preferredTz]
    )
  })

  const pathname = usePathname()
  const onSettingsPage = pathname === routeDirectory.settings.root

  const notPreferred = actualTz !== preferredTz
  const shouldPrompt = notPreferred && (onSettingsPage || actualTz !== storedTz?.actual)
  return { actualTz, storedTz, setStoredTz, removeStoredTz, preferredTz, onSettingsPage, shouldPrompt }
}

function formatTimezone(tz: string) {
  return replaceMap(tz, {
    '/': ' - ',
    _: ' '
  })
}

/**
 * Removes the user-specific timezone snooze setting from localStorage. This is done at sign-in so
 * that the user is prompted to update their timezone on each session (see the `AuthProvider`)
 */
export function clearTimezoneSnooze(userId: string) {
  window.localStorage.removeItem([userId, storageKey].join('/'))
}
