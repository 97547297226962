import type { TypographyProps } from '@mui/material'
import { Box, Typography, alpha, styled } from '@mui/material'
import type { PropsWithChildren } from 'react'

import LoadingButton from '~/components/LoadingButton'

type BannerBarProps = PropsWithChildren<{
  backgroundColor: string
}>

export function BannerBar({ children, backgroundColor }: BannerBarProps) {
  return (
    <Box
      sx={{
        bgcolor: backgroundColor,
        color: 'primary.contrastText',
        px: 4,
        py: 2
      }}
    >
      {children}
    </Box>
  )
}

type BannerButtonProps = { darkBackground?: boolean }

export const BannerButton = styled(LoadingButton, {
  shouldForwardProp: (prop) => prop !== 'darkBackground'
})<BannerButtonProps>(({ theme, darkBackground = false }) => {
  const color = darkBackground ? theme.palette.common.white : theme.palette.text.secondary
  return {
    color,
    borderColor: color,
    flexShrink: 0,
    '&:hover': {
      border: `1px solid ${color}`,
      backgroundColor: alpha(color, theme.palette.action.hoverOpacity * 2) + ' !important'
    }
  }
})

type BannerTypographyProps = TypographyProps & { bold?: true }

export function BannerTypography({ bold, ...rest }: BannerTypographyProps) {
  return <Typography component="span" variant="body2" fontWeight={bold ? 700 : undefined} {...rest} />
}
