import BlankLayout from '@core/layouts/BlankLayout'

import MfaSetup from '~/auth/components/MfaSetup'
import TermsOfService from '~/auth/components/TermsOfService'
import Center from '~/components/Center'
import { useActualOrg } from '~/data/api/hooks/organizations'
import { useCurrentUser } from '~/data/api/hooks/users'

type InterstitialPageProps = {
  status: InterstitalStatus
}

type UseInterstitialStatusResponse = {
  status: InterstitalStatus | null
  isLoading: boolean
}

export type InterstitalStatus = 'SETUP_MFA' | 'ACCEPT_TERMS'

export const useInterstitialStatus = (): UseInterstitialStatusResponse => {
  const { synopUser, isUserLoading } = useCurrentUser()
  const { data: userOrg, isLoading: isOrgLoading } = useActualOrg()

  const { termsAccepted, mfaSetupStatus } = synopUser ?? {}

  const isLoading = isUserLoading || isOrgLoading

  if (userOrg?.mfaStatus === 'ON_REQUIRED' && mfaSetupStatus !== 'COMPLETED') return { status: 'SETUP_MFA', isLoading }
  else if (!termsAccepted) return { status: 'ACCEPT_TERMS', isLoading }

  return { status: null, isLoading }
}

export default function InterstitialPage({ status }: InterstitialPageProps) {
  const statusMap = {
    ACCEPT_TERMS: <TermsOfService />,
    SETUP_MFA: <MfaSetup />
  }

  return (
    <BlankLayout>
      <Center.Middle>{statusMap[status]}</Center.Middle>
    </BlankLayout>
  )
}
