import { Stack } from '@mui/material'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import Center from '~/components/Center'
import Link from '~/components/Link'

export default function NotAuthorized() {
  return (
    <Center.Middle>
      <Stack spacing={10} alignItems="center">
        <Typography variant="h1">401</Typography>

        <Stack spacing={0} alignItems="center">
          <Typography variant="h4">You are not authorized! 🔐</Typography>
          <Typography variant="body2">You don&prime;t have permission to access this page.</Typography>
        </Stack>

        <Button href="/" component={Link} variant="contained" sx={{ px: 5.5 }}>
          Back to Home
        </Button>
      </Stack>
    </Center.Middle>
  )
}
