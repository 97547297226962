import { keyBy } from 'lodash'
import { useMemo } from 'react'

import { useCurrentOrgId } from '~/store/slices/currentOrg'

import type { GetVehiclesForOrganizationArgs, VehicleModel } from '../client'
import { useGetVehicleQuery, useGetVehiclesBySiteQuery, useGetVehiclesForOrganizationQuery } from '../client'
import type { UsePollingOptions, UseQueryOptions } from './polling'
import { usePolling } from './polling'
import { useVehicleHomeSiteFilterCallback } from './useHomeSiteFiltering'
import { combineSkip, useCaseInsensitiveDict } from './utils'

/******************************************************************************
 * GET /organizations/{orgId}/vehicles
 ******************************************************************************/
export function usePolledVehicles(args?: GetVehiclesForOrganizationArgs, options?: UsePollingOptions) {
  const currentOrgId = useCurrentOrgId()
  const orgId = args?.id ?? currentOrgId
  const result = usePolling(useGetVehiclesForOrganizationQuery, { id: orgId }, combineSkip(!orgId, options))

  const filter = useVehicleHomeSiteFilterCallback()
  const vehicles = useMemo(() => result.data?.filter(filter) ?? [], [result.data, filter])
  return { vehicles, ...result }
}

export function useVehicles(args?: GetVehiclesForOrganizationArgs, options?: UsePollingOptions) {
  return usePolledVehicles(args, { ...options, intervalSeconds: 0 })
}

export function useOrgVehicles(orgId: string) {
  return useVehicles({ id: orgId })
}

export function useSiteVehicles(siteId: string) {
  const result = usePolling(useGetVehiclesBySiteQuery, { id: siteId }, { intervalSeconds: 0 })

  return { vehicles: result.data || [], ...result }
}

export function useAtHomeVehicles(siteId: string) {
  const { vehicles } = useSiteVehicles(siteId)
  return useMemo(() => {
    return vehicles.filter((vehicle) => {
      return vehicle.currentLocation?.locationOfInterestModels?.some((loi) => loi.id === siteId)
    })
  }, [vehicles, siteId])
}

export function useCurrentOrgVehicles() {
  return useOrgVehicles(useCurrentOrgId())
}

export function useOrgVehiclesMap(args?: GetVehiclesForOrganizationArgs, options?: UsePollingOptions) {
  const { isSuccess, isLoading, isFetching, vehicles } = usePolledVehicles(args, options)
  const orgVehicles = useMemo(() => keyBy(vehicles ?? [], 'id'), [vehicles])

  return {
    orgVehicles,
    isLoading,
    isFetching,
    isSuccess,
    lookup: (vehicleId?: string) => (vehicleId ? orgVehicles[vehicleId] : undefined)
  }
}

export function useVehicleMapByMacAddress(vehiclesInput?: VehicleModel[]) {
  // Fetch vehicles if they are not provided
  const { vehicles: vehiclesFetched, ...result } = useVehicles(undefined, { skip: !!vehiclesInput })
  const vehicles = vehiclesInput ?? vehiclesFetched

  const withMacAddress = useMemo(() => {
    const transformedVehicles = vehicles.filter((v) => !!v.macAddress)
    return keyBy(transformedVehicles, 'macAddress')
  }, [vehicles])

  return { vehicleMapByMacAddress: useCaseInsensitiveDict(withMacAddress), ...result }
}

export function useVehicleByMacAddress(macAddress?: string) {
  const { vehicleMapByMacAddress, ...result } = useVehicleMapByMacAddress()

  return useMemo(() => {
    return { vehicle: vehicleMapByMacAddress[macAddress ?? ''], ...result }
  }, [result, vehicleMapByMacAddress, macAddress])
}

/******************************************************************************
 * GET /vehicles/{vehicleId}
 ******************************************************************************/
export function usePolledVehicle(vehicleId?: string, options?: UsePollingOptions) {
  const result = usePolling(useGetVehicleQuery, { id: vehicleId ?? '' }, combineSkip(!vehicleId, options))

  // Ensure the vehicle returned from the hook matches the ID passed in. This is needed when the ID
  // changes or is cleared.
  const vehicle = result.data?.id === vehicleId ? result.data : undefined
  return { vehicle, ...result }
}

export function useVehicle(vehicleId?: string, options?: UseQueryOptions) {
  return usePolledVehicle(vehicleId, { ...options, intervalSeconds: 0 })
}
