import { InfoOutlined } from '@mui/icons-material'
import type { SvgIconProps, TooltipProps } from '@mui/material'
import { Tooltip } from '@mui/material'

type InfoTooltipProps = Omit<TooltipProps, 'children'> & { IconProps?: SvgIconProps }

export default function InfoTooltip({ IconProps, placement = 'top', ...props }: InfoTooltipProps) {
  if (!props.title) return null
  return (
    <Tooltip {...props} arrow placement={placement}>
      <InfoOutlined {...IconProps} />
    </Tooltip>
  )
}
