import { MongoAbility } from '@casl/ability'
import { usePathname, useRouter } from 'next/navigation'
import { ReactNode, useEffect } from 'react'

import { LoadingMessage } from '~/components/Loaders'
import type { ACLObj } from '~/configs/acl'
import { buildAbilityFor } from '~/configs/acl'
import { useAuth } from '~/hooks/useAuth'
import { AbilityContext } from '~/layouts/components/acl/Can'
import getHomeRoute from '~/layouts/components/acl/getHomeRoute'
import NotAuthorized from '~/layouts/errors/NotAuthorized'

interface AclGuardProps {
  children: ReactNode
  aclAbilities: ACLObj
}

const AclGuard = (props: AclGuardProps) => {
  // ** Props
  const { aclAbilities, children } = props

  // ** Hooks
  const auth = useAuth()
  const router = useRouter()
  const pathname = usePathname()

  // ** Vars
  let ability: MongoAbility | undefined

  useEffect(() => {
    if (auth.user && pathname === '/') {
      router.replace(getHomeRoute())
    }
  }, [auth.user, pathname, router])

  // User is logged in, build ability for the user based on their role
  if (auth.user && !ability) {
    ability = buildAbilityFor(auth.user)
    if (pathname === '/') {
      return <LoadingMessage />
    }
  }

  // Check the access of current user and render pages
  if (ability && auth.user && ability.can(aclAbilities.action, aclAbilities.subject)) {
    if (pathname === '/') {
      return <LoadingMessage />
    } else {
      return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
    }
  }

  // Render Not Authorized component if the current user has limited access
  return <NotAuthorized />
}

export default AclGuard
