import { configureStore } from '@reduxjs/toolkit'

import { enhancedApi } from '~/data/api/client'

import reducer from './reducers'

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(enhancedApi.middleware)
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store
