import { Alert, Snackbar as MuiSnackbar, Paper } from '@mui/material'
import Fade from '@mui/material/Fade'

import { useSnackbar, useSnackbarControls } from '~/store/slices/display'

export default function Snackbar() {
  const { isOpen, message, severity, title } = useSnackbar()
  const { closeSnackbar } = useSnackbarControls()

  return (
    <MuiSnackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={4000}
      onClose={closeSnackbar}
      open={isOpen}
      TransitionComponent={Fade}
    >
      <Paper elevation={3}>
        <Alert severity={severity ?? 'success'} sx={{ width: '100%' }}>
          {title && <b>{title}</b>} {message}
        </Alert>
      </Paper>
    </MuiSnackbar>
  )
}
