import { alpha } from '@mui/material'

import { OwnerStateThemeType } from './'

const input = () => {
  return {
    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }: OwnerStateThemeType) => ({
          color: theme.palette.text.secondary
        })
      }
    },
    MuiInput: {
      styleOverrides: {
        root: ({ theme }: OwnerStateThemeType) => ({
          '&:before': {
            borderBottom: `1px solid ${alpha(theme.palette.customColors.main, 0.22)}`
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: `1px solid ${alpha(theme.palette.customColors.main, 0.32)}`
          },
          '&.Mui-disabled:before': {
            borderBottomStyle: 'solid'
          }
        })
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: ({ theme }: OwnerStateThemeType) => ({
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          backgroundColor: alpha(theme.palette.customColors.main, 0.05),
          '&:hover:not(.Mui-disabled)': {
            backgroundColor: alpha(theme.palette.customColors.main, 0.08)
          },
          '&:before': {
            borderBottom: `1px solid ${alpha(theme.palette.customColors.main, 0.22)}`
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: `1px solid ${alpha(theme.palette.customColors.main, 0.32)}`
          },
          '&.Mui-disabled': {
            backgroundColor: alpha(theme.palette.customColors.main, 0.05),
            '&:before': {
              borderBottomStyle: 'solid'
            }
          }
        })
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8
        }
      }
    }
  }
}

export default input
