'use client'

import { CacheProvider } from '@emotion/react'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { LicenseInfo } from '@mui/x-license'
import { usePathname } from 'next/navigation'
import 'prismjs'
import 'prismjs/components/prism-jsx'
import 'prismjs/components/prism-tsx'
import type { ReactNode } from 'react'
import { useEffect } from 'react'
import { Provider as ReduxProvider } from 'react-redux'

import AclGuard from '@core/components/auth/AclGuard'
import AuthGuard from '@core/components/auth/AuthGuard'
import { SettingsConsumer, SettingsProvider } from '@core/context/settingsContext'
import ThemeComponent from '@core/theme/ThemeComponent'
import { createEmotionCache } from '@core/utils/create-emotion-cache'

import AuthProvider from '~/auth/AuthProvider'
import getCustomerAssets from '~/auth/assets'
import { LoadingMessage } from '~/components/Loaders'
import Snackbar from '~/components/Snackbar'
import { defaultACLObj } from '~/configs/acl'
import UserLayout from '~/layouts/UserLayout'
import Rollbar from '~/layouts/errors/Rollbar'
import store from '~/store'

const emotionCache = createEmotionCache()
LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_LICENSE_KEY ?? '')

// ** Configure JSS & ClassName
export default function AppContainer({ children }: { children: ReactNode }) {
  const pathname = usePathname()
  useEffect(() => {
    const { favicon } = getCustomerAssets() ?? {}
    if (favicon) {
      const iconLink = document.querySelector("link[rel~='icon']")
      iconLink?.setAttribute('href', favicon)
    }
  }, [pathname])

  return (
    <Rollbar>
      <ReduxProvider store={store}>
        <CacheProvider value={emotionCache}>
          <SettingsProvider>
            <SettingsConsumer>
              {({ settings }) => {
                return (
                  <ThemeComponent settings={settings}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <AuthProvider>
                        <AuthGuard fallback={<LoadingMessage />}>
                          <AclGuard aclAbilities={defaultACLObj}>
                            <UserLayout contentHeightFixed={false}>{children}</UserLayout>
                          </AclGuard>
                        </AuthGuard>
                        <Snackbar />
                      </AuthProvider>
                    </LocalizationProvider>
                  </ThemeComponent>
                )
              }}
            </SettingsConsumer>
          </SettingsProvider>
        </CacheProvider>
      </ReduxProvider>
    </Rollbar>
  )
}
