import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { map, sortBy } from 'lodash'
import { useSelector } from 'react-redux'

import type { NotificationModel } from '~/data/api/client'
import type { PartialStore } from '~/store/types'

type NotificationOverlayStore = PartialStore<typeof notificationOverlaySlice>
type NotificationOverlayState = {
  overlayOpen: boolean
  overlayNotifications: NotificationModel[]
}

const initialState: NotificationOverlayState = {
  overlayOpen: false,
  overlayNotifications: []
}

const notificationOverlaySlice = createSlice({
  name: 'notificationOverlay',
  initialState,
  reducers: {
    setOverlayOpen: (state, action: PayloadAction<boolean>) => {
      state.overlayOpen = action.payload
      return state
    },
    setOverlayNotifications: (state, action: PayloadAction<NotificationModel[]>) => {
      state.overlayNotifications = sortBy(action.payload, 'active')
      state.overlayOpen = state.overlayNotifications.length > 0
      return state
    },
    appendOverlayNotifications: (state, action: PayloadAction<NotificationModel[]>) => {
      const currNotifications = map(state.overlayNotifications, 'id')
      const newNotifications = action.payload.filter(({ id }) => !currNotifications.includes(id))
      state.overlayNotifications = sortBy([...state.overlayNotifications, ...newNotifications], 'active')

      // When new notifications arrive, open the overlay if it's not already open. Do not
      // close the overlay if it's already open.
      if (!state.overlayOpen) state.overlayOpen = newNotifications.length > 0

      return state
    },
    addOverlayNotification: (state, action: PayloadAction<NotificationModel>) => {
      state.overlayNotifications.push(action.payload)
      return state
    },
    clearOverlayNotifications: (state) => {
      state.overlayNotifications = []
      state.overlayOpen = false
      return state
    },
    removeOverlayNotifications: (state, action: PayloadAction<string[]>) => {
      state.overlayNotifications = state.overlayNotifications.filter(({ id }) => !action.payload.includes(id))
      if (state.overlayOpen && state.overlayNotifications.length === 0) {
        state.overlayOpen = false
      }
      return state
    }
  }
})

export const {
  setOverlayOpen,
  setOverlayNotifications,
  appendOverlayNotifications,
  addOverlayNotification,
  clearOverlayNotifications,
  removeOverlayNotifications
} = notificationOverlaySlice.actions
export default notificationOverlaySlice.reducer

const selectOverlayNotifications = (state: NotificationOverlayStore) => state.notificationOverlay.overlayNotifications
const selectOverlayOpen = (state: NotificationOverlayStore) => state.notificationOverlay.overlayOpen
export const useOverlayNotifications = () => useSelector(selectOverlayNotifications)
export const useNotificationOverlayOpen = () => useSelector(selectOverlayOpen)
