import { Box, Button, Tab, Tabs, Typography } from '@mui/material'
import { signOut } from 'aws-amplify/auth'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import { LoadingMessage } from '~/components/Loaders'
import LoadingButton from '~/components/LoadingButton'
import type { OrganizationTerms } from '~/data/api/client'
import { useGetOrganizationTermsQuery, useUpdateUserMutation } from '~/data/api/client'
import { useCurrentOrg } from '~/data/api/hooks/organizations'
import { useCurrentUser } from '~/data/api/hooks/users'

import AuthPage from './AuthPage'

const TermsOfService = () => {
  const { handleSubmit } = useForm()
  const [currentTab, setCurrentTab] = useState(0)
  const { org: currentOrg, isLoading: isFleetLoading } = useCurrentOrg()
  const { synopUser, isUserLoading } = useCurrentUser()

  const { data: fleetTermsData = [], isLoading: isFleetTermsLoading } = useGetOrganizationTermsQuery(
    { id: currentOrg?.id || '' },
    { skip: !currentOrg?.id }
  )

  const [updateUser, updateUserResponse] = useUpdateUserMutation()

  if (isFleetLoading || isFleetTermsLoading || isUserLoading) {
    return <LoadingMessage />
  }

  const onSubmit = async () => {
    if (synopUser)
      await updateUser({
        userModel: {
          ...synopUser,
          termsAccepted: true,
          termsAcceptedDt: new Date().toISOString()
        }
      })
  }

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue)
  }
  const TermsTabPanels = fleetTermsData.map((orgTerms, i) => (
    <TermsTabPanel key={orgTerms.id} index={i} orgTerms={orgTerms} value={currentTab} />
  ))

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <AuthPage
        actions={
          <>
            <Button onClick={() => signOut()}>Cancel</Button>
            <LoadingButton loading={updateUserResponse.isLoading} type="submit" variant="contained">
              I Agree
            </LoadingButton>
          </>
        }
        subtitle="Please agree to the following Terms of Service to continue."
        title="Terms of Service"
      >
        {fleetTermsData.length > 1 && (
          <Tabs onChange={handleChange} value={currentTab}>
            {fleetTermsData.map((orgTerms) => (
              <Tab key={orgTerms.id} label={orgTerms.documentTitle} />
            ))}
          </Tabs>
        )}
        {TermsTabPanels}
      </AuthPage>
    </form>
  )
}

type TabPanelProps = {
  index: number
  value: number
  orgTerms: OrganizationTerms
}

function TermsTabPanel({ value, index, orgTerms }: TabPanelProps) {
  const { terms } = orgTerms
  return (
    <div hidden={value !== index}>
      {value === index && (
        <Box sx={{ height: '216px', overflowY: 'scroll' }}>
          <Typography>{terms && <div dangerouslySetInnerHTML={{ __html: terms }} />}</Typography>
        </Box>
      )}
    </div>
  )
}

export default TermsOfService
