import { Button, Stack, Typography } from '@mui/material'
import { QueryStatus } from '@reduxjs/toolkit/query'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import QRCode from 'react-qr-code'

import Center from '~/components/Center'
import { LoadingMessage } from '~/components/Loaders'
import TextFormField from '~/components/forms/TextField'
import { useInitiateMfaSetupQuery, useVerifySoftwareTokenMutation } from '~/data/api/client'
import { useActualOrg } from '~/data/api/hooks/organizations'
import { useCurrentUser } from '~/data/api/hooks/users'
import { useSnackbarControls } from '~/store/slices/display'

type MfaAuthFormData = {
  userCode: string
}

type MfaQrCodeProps = {
  onCancel: () => void
  onSuccess?: () => void
  onError?: () => void
  isRequired?: boolean
}

export default function MfaQrCode({ onCancel, onSuccess, onError, isRequired = false }: MfaQrCodeProps) {
  const { synopUser } = useCurrentUser()
  const { org: userOrg } = useActualOrg()
  const { openSnackbar } = useSnackbarControls()
  const { data: mfaSetup, isError } = useInitiateMfaSetupQuery()
  const [setupMfa, setupMfaResponse] = useVerifySoftwareTokenMutation()

  const formMethods = useForm<MfaAuthFormData>()
  const { handleSubmit } = formMethods

  const appLabel = userOrg?.organizationNm ?? 'Synop'
  const { secretCode } = mfaSetup ?? {}
  const authUrl = `otpauth://totp/${appLabel}:${synopUser?.email}?secret=${secretCode}&issuer=${appLabel}`

  useEffect(() => {
    if (setupMfaResponse.status === QueryStatus.fulfilled) {
      openSnackbar('You will now need to use your authentication application each time you sign in.', {
        title: 'MFA Setup Successful'
      })
      onSuccess?.()
    }
  }, [onSuccess, openSnackbar, setupMfaResponse.status])

  // This happens when users have their tokens revoked from MFA being required
  useEffect(() => {
    if (isError) onError?.()
  }, [isError, onError])

  if (!synopUser || !secretCode) return <LoadingMessage />

  return (
    <Stack spacing={4}>
      {isRequired && <Typography variant="body1">Your organization requires Multi-factor Authentication.</Typography>}
      <Typography variant="body1">
        Scan this QR code with your authentication application then input the code provided by your authentication app
        to finish setup.
      </Typography>
      <Center.Horizontal>
        <QRCode data-dd-privacy="hidden" value={authUrl} />
      </Center.Horizontal>
      <Center.Horizontal>
        <form onSubmit={handleSubmit(setupMfa)}>
          <FormProvider {...formMethods}>
            <TextFormField name="userCode" inputProps={{ maxLength: 6 }} label="Authentication code" type="tel" />
          </FormProvider>
        </form>
      </Center.Horizontal>
      {setupMfaResponse.status === QueryStatus.rejected && (
        <Center.Horizontal>
          <Typography color="error" variant="subtitle1">
            Authentication setup failed. Please try again.
          </Typography>
        </Center.Horizontal>
      )}
      <Typography variant="body1">You will need to use your authentication app each time you sign in.</Typography>
      <Stack direction="row" justifyContent="flex-end" spacing={4} sx={{ pt: 2, pr: 4 }}>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={handleSubmit(setupMfa)} variant="contained">
          Save
        </Button>
      </Stack>
    </Stack>
  )
}
