import MuiChip from '@mui/material/Chip'
import clsx from 'clsx'
import useBgColor from 'src/@core/hooks/useBgColor'

import { CustomChipProps } from './types'

const Chip = (props: CustomChipProps) => {
  // ** Props
  const { sx, skin, color, rounded } = props

  // ** Hook
  const bgColors = useBgColor()

  const colors = {
    primary: { ...bgColors.primaryLight },
    secondary: { ...bgColors.secondaryLight },
    success: { ...bgColors.successLight },
    error: { ...bgColors.errorLight },
    warning: { ...bgColors.warningLight },
    info: { ...bgColors.infoLight },
    default: {},

    // Custom Colors
    purple: { ...bgColors.purpleLight },
    orange: { ...bgColors.orangeLight },
    teal: { ...bgColors.tealLight },
    aqua: { ...bgColors.aquaLight },
    cornflower: { ...bgColors.cornflowerLight },
    navy: { ...bgColors.navyLight },
    unknown: { ...bgColors.unknownLight }
  }

  const propsToPass = { ...props }

  propsToPass.rounded = undefined

  return (
    <MuiChip
      {...propsToPass}
      variant="filled"
      className={clsx({
        'MuiChip-rounded': rounded,
        'MuiChip-light': skin === 'light'
      })}
      sx={skin === 'light' && color ? { ...colors[color], ...sx } : sx}
    />
  )
}

export default Chip
