import { datadogRum } from '@datadog/browser-rum'
import { ErrorBoundary, Provider as RollbarProvider } from '@rollbar/react'
import { get } from 'lodash'
import type { PropsWithChildren } from 'react'
import { useMemo } from 'react'
import type { Configuration } from 'rollbar'

import { getUserData } from '~/auth/AuthProvider'
import type { Config } from '~/configs/env'
import envConfig from '~/configs/env'

type RollbarProps = PropsWithChildren<{ config?: Config }>

export default function Rollbar({ children, config = envConfig }: RollbarProps) {
  const rollbarConfig = useMemo<Configuration>(
    () => ({
      accessToken: config.rollbarClientAccessToken,
      captureUncaught: true,
      captureUnhandledRejections: true,
      enabled: ['prod', 'gamma', 'beta'].includes(config.rollbarEnv),
      environment: config.rollbarEnv,
      payload: {
        client: {
          javascript: {
            code_version: config.appVersion,
            source_map_enabled: true,

            // Rollbar will guess which frames the error was thrown from when the browser does not
            // provide line  and column numbers.
            guess_uncaught_frames: true
          }
        }
      },

      // Add the Datadog session URL and user data to the Rollbar payload
      transform: (payload) => {
        payload.sessionUrl = datadogRum?.getSessionReplayLink()

        const { id, email } = getUserData() ?? {}
        payload.userId = id
        payload.userEmail = email

        // To make the stack trace usable in the source maps, we need to replace the hostnames in
        // the filenames with the string "dynamichost".
        const frames = get(payload, 'body.trace.frames', [])
        if (Array.isArray(frames)) {
          // Regex matches the protocol, then discards the hostname and captures the rest of the URL
          const locRegex = /^(https?):\/\/[^/]+(.*)/
          frames.forEach((frame) => {
            const filename = frame.filename
            if (filename) {
              const m = filename.match(locRegex)
              frame.filename = m[1] + '://dynamichost' + m[2]
            }
          })
        }
      }
    }),
    [config]
  )

  // Render the provider and an error boundary. The error boundary here is mainly for catching errors
  // thrown in the `AppContainer` component, not errors thrown in page components (that's handled by
  // `src/app/error.tsx`).
  return (
    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </RollbarProvider>
  )
}
